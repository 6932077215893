// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PopBooks,
.YouTube,
.News,
.playStore,
.newsletter,
.kpi,
.ft {
    display: flex;
    justify-content: center;
}

.ft {
    background: #282828;
}

.kpi {
    align-items: center;
}

.carousel {
    margin-top: 30px;
    background-color: #FCEDEC;
}

.carousel .css-cce6m-MuiCardContent-root:last-child {
    padding-top: 32px !important;
    padding-bottom: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/homepage/homepage.css"],"names":[],"mappings":"AAAA;;;;;;;IAOI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,+BAA+B;AACnC","sourcesContent":[".PopBooks,\n.YouTube,\n.News,\n.playStore,\n.newsletter,\n.kpi,\n.ft {\n    display: flex;\n    justify-content: center;\n}\n\n.ft {\n    background: #282828;\n}\n\n.kpi {\n    align-items: center;\n}\n\n.carousel {\n    margin-top: 30px;\n    background-color: #FCEDEC;\n}\n\n.carousel .css-cce6m-MuiCardContent-root:last-child {\n    padding-top: 32px !important;\n    padding-bottom: 16px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
