// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsLetter{
    border-radius: 20px;
    background-color: #FCCC4D;
    color: #363636;
    display: flex;
    padding: 30px 0;
    justify-content: space-around;
    align-items: center;
}
.NewsLetter .title{
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 40px;
}
.NewsLetter .subtitle{
    font-size: 0.8rem;
    font-weight: 400;
    padding: 10px 0;
}
.subscribe{
    width: 20vw;
    max-width: 100%;
    position: relative;
    margin: 10px 0;
}
.subscribe input {
    border-radius: 50px;
    padding: 1rem 2rem;
    width: 100%;
    border: none;
}
.subscribe input::placeholder{
    color: #999999;
}
.subscribe button{
    background-color: #F09300;
    width: 40%;
    border-radius: 50px;
    height: 100%;
    aspect-ratio: 1;
    font-size: 0.8rem;
    font-weight: 700;
    position: absolute;
    right: -33%;
    top: 0;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/newsLetter/newsLetter.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;AACA;IACI,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,UAAU;IACV,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,MAAM;IACN,YAAY;AAChB","sourcesContent":[".NewsLetter{\n    border-radius: 20px;\n    background-color: #FCCC4D;\n    color: #363636;\n    display: flex;\n    padding: 30px 0;\n    justify-content: space-around;\n    align-items: center;\n}\n.NewsLetter .title{\n    font-size: 1.6rem;\n    font-weight: 700;\n    line-height: 40px;\n}\n.NewsLetter .subtitle{\n    font-size: 0.8rem;\n    font-weight: 400;\n    padding: 10px 0;\n}\n.subscribe{\n    width: 20vw;\n    max-width: 100%;\n    position: relative;\n    margin: 10px 0;\n}\n.subscribe input {\n    border-radius: 50px;\n    padding: 1rem 2rem;\n    width: 100%;\n    border: none;\n}\n.subscribe input::placeholder{\n    color: #999999;\n}\n.subscribe button{\n    background-color: #F09300;\n    width: 40%;\n    border-radius: 50px;\n    height: 100%;\n    aspect-ratio: 1;\n    font-size: 0.8rem;\n    font-weight: 700;\n    position: absolute;\n    right: -33%;\n    top: 0;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
