// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter{
    display: inline-flex;
    gap: 10px;
    font-size: 1rem;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    padding: 10px;
    background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/cart/cart.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,SAAS;IACT,eAAe;IACf,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".counter{\n    display: inline-flex;\n    gap: 10px;\n    font-size: 1rem;\n    border: 1px solid #E6E6E6;\n    border-radius: 10px;\n    padding: 10px;\n    background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
