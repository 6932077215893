// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BuyNow{
    font-size: 1rem;
    font-weight: 600;
    width:70%;
    background-color: #F09300;
    padding: 17px 20px 17px 20px;
    border-radius: 50px;
    border:None
}`, "",{"version":3,"sources":["webpack://./src/components/buyButton/buyButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,yBAAyB;IACzB,4BAA4B;IAC5B,mBAAmB;IACnB;AACJ","sourcesContent":[".BuyNow{\n    font-size: 1rem;\n    font-weight: 600;\n    width:70%;\n    background-color: #F09300;\n    padding: 17px 20px 17px 20px;\n    border-radius: 50px;\n    border:None\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
