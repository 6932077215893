// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.book-cards{
    display: flex;
}
.book-cards > .card{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.book-cards .content > * {
    padding-bottom: 10px;
}
.book-cards .image{
    width:85%
}
.book-cards .title{
    font-size: 1.2rem;
    font-weight: 600;
}
.desc{
    font-size: 1rem;
    font-weight: 400;
}
.cost{
    font-size: 1.2rem;
    font-weight: 600;
    color: #F09300;
}
.buyButton{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/popularBooks/popularBooks.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;IACI,oBAAoB;AACxB;AACA;IACI;AACJ;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,WAAW;AACf","sourcesContent":[".book-cards{\n    display: flex;\n}\n.book-cards > .card{\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n.book-cards .content > * {\n    padding-bottom: 10px;\n}\n.book-cards .image{\n    width:85%\n}\n.book-cards .title{\n    font-size: 1.2rem;\n    font-weight: 600;\n}\n.desc{\n    font-size: 1rem;\n    font-weight: 400;\n}\n.cost{\n    font-size: 1.2rem;\n    font-weight: 600;\n    color: #F09300;\n}\n.buyButton{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
