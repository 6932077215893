// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.latestnews{
    width: 100%;
}
.subtitle-section{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 10px;
}
.subtitle-section .subtitle{
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/latestnews/latestnews.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,aAAa;IACb,qBAAqB;IACrB,8BAA8B;IAC9B,SAAS;AACb;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".latestnews{\n    width: 100%;\n}\n.subtitle-section{\n    display: flex;\n    align-items: baseline;\n    justify-content: space-between;\n    gap: 10px;\n}\n.subtitle-section .subtitle{\n    font-size: 1.2rem;\n    font-weight: 600;\n    letter-spacing: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
