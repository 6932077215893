// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Cards{
    display: flex;
    height: 100%;
    background-color: #F6F4FF;
    padding: 15px 30px 15px 30px;
    gap: 15px;
    border-radius: 15px;
    flex-direction: column;
}
.card{
    /* padding-left: 30px; */
    display:flex;
    align-items: center;
    gap:30px;
}
.card-img{
    width: 4vw;
    height: 8vh;
}
.card-text{
    font-size: 1rem;
    color: #F09300;
}
.card-text::after {
    content: '\\003E';  /* Unicode for '>' */
    margin-left: 5px;
}

a {
    text-decoration: none;  /* Removes the underline */
}`, "",{"version":3,"sources":["webpack://./src/components/shopByEditionCard/shopByEditionCard.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,YAAY;IACZ,yBAAyB;IACzB,4BAA4B;IAC5B,SAAS;IACT,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,wBAAwB;IACxB,YAAY;IACZ,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,eAAe;IACf,cAAc;AAClB;AACA;IACI,gBAAgB,GAAG,oBAAoB;IACvC,gBAAgB;AACpB;;AAEA;IACI,qBAAqB,GAAG,0BAA0B;AACtD","sourcesContent":["\n.Cards{\n    display: flex;\n    height: 100%;\n    background-color: #F6F4FF;\n    padding: 15px 30px 15px 30px;\n    gap: 15px;\n    border-radius: 15px;\n    flex-direction: column;\n}\n.card{\n    /* padding-left: 30px; */\n    display:flex;\n    align-items: center;\n    gap:30px;\n}\n.card-img{\n    width: 4vw;\n    height: 8vh;\n}\n.card-text{\n    font-size: 1rem;\n    color: #F09300;\n}\n.card-text::after {\n    content: '\\003E';  /* Unicode for '>' */\n    margin-left: 5px;\n}\n\na {\n    text-decoration: none;  /* Removes the underline */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
